import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/components/home.css'
import LoadingWithText from './loading_text';
import axios from 'axios';
import { universeListStore } from '../../stores/universe_list_store';
import { getApiServrUrl, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { set, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { recentCompaniesStore } from '../../stores/recent_companies_store';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';

axios.defaults.withCredentials = true;

const HomeComponent = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [universeLaoding, setUniverseLoading] = useState(false);
    const [sharedUniverseLoading, setSharedUniverseLoading] = useState(false);
    const [shareUniverseProcessing, setShareUniverseProcessing] = useState(false);
    const [universes, setUniverses] = useState([]);
    const [sharedUniverses, setSharedUniverses] = useState([]);
    const [recentCompanies, setRecentCompanies] = useState([]);
    const [user, setUser] = useState(null);
    const [userDataLoading, setUserDataLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPageSharedUniverses, setCurrentPageSharedUniverses] = useState(1);
    const [itemsPerPageSharedUniverse, setItemsPerPageSharedUniverse] = useState(10);
    
    const pageCount = Math.ceil(universes.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = universes.slice(indexOfFirstItem, indexOfLastItem);

    const pageCountSharedUniverses = Math.ceil(sharedUniverses.length / itemsPerPageSharedUniverse);
    const indexOfLastItemSharedUniverses = currentPageSharedUniverses * itemsPerPageSharedUniverse;
    const indexOfFirstItemSharedUniverses = indexOfLastItemSharedUniverses - itemsPerPageSharedUniverse;
    const currentItemsSharedUniverses = sharedUniverses.slice(indexOfFirstItemSharedUniverses, indexOfLastItem);

    const [selectedUniverses, setSelectedUniverses] = useState({});

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shareEmails, setShareEmails] = useState('Enter up to 5 emails here separated by a comma');

    const location = useLocation();
    const navigate = useNavigate();

    const fromLogin = location.state ? location.state.fromLogin : false;

    const handleCheckboxChange = (universeId, isChecked) => {
        setSelectedUniverses(prevSelected => ({
            ...prevSelected,
            [universeId]: isChecked
        }));
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const handleShareEmailInputChange = (e) => {
        // split the emails by comma
        setShareEmails(e.target.value.toLowerCase());
    };

    const shareUniverses = async () => {

        if(shareEmails === "" || shareEmails === "Enter up to 5 emails here separated by a comma") {
            alert('Email(s) required to share the collection.');
            return;
        }
        let shareEmailsArray = shareEmails.split(',').map(email => email.trim());
        if (shareEmailsArray.length > 5) {
            alert('Error: You can only share a collection with up to 5 emails at a time.');
            return;
        }

        if (shareEmailsArray.length === 1 && shareEmailsArray[0] === user.email) {
            alert('Error: You cannot share a collection with yourself.');
            return;
        }

        // check if all the emails are valid
        for (let email of shareEmailsArray) {
            if (!email.includes('@') || !email.includes('.')) {
                alert(`Error: ${email} is not a valid email.`);
                return;
            }
        }
        
        if (userAccountDataStore.accessExpiresInDays <= 0) {
            alert('Your access has expired. Please contact support for assistance.');
            return;
        }

        setShareUniverseProcessing(true);
        // Logic to share universes
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_SHARE_UNIVERSES_ENDPOINT;

            let data = {
                "universe_ids": Object.entries(selectedUniverses)
                    .filter(([_, isSelected]) => isSelected)
                    .map(([id, _]) => id),
                "emails": shareEmailsArray,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                decompress: true,
            };

            const response = await axios.post(apiUrl, data, config);
            // get ids from selectedUniverses
            let selectedUniverseIds = Object.entries(selectedUniverses)
                .filter(([_, isSelected]) => isSelected)
                .map(([id, _]) => id);

            if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                mixpanelActions.track("Click", {
                    location: "Home",
                    type: "Share Collections",
                    universeIds: selectedUniverseIds,
                    sharedWith: shareEmailsArray,
                    user: toJS(userAccountDataStore.user),
                });
                amplitudeActions.track("Click", {
                    location: "Home",
                    type: "Share Collections",
                    universeIds: selectedUniverseIds,
                    sharedWith: shareEmailsArray,
                    user: toJS(userAccountDataStore.user),
                });
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setShareEmails('Enter up to 5 emails here separated by a comma');
            setShareUniverseProcessing(false);
            // Close the modal after sharing
            setIsModalVisible(false);

            // force reload the entire page
            window.location.reload();

        }
    };

    const handleCompanySearchClick = () => {
        if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
            mixpanelActions.track("Click", {
                location: "Home",
                type: "Company Search",
                user: toJS(userAccountDataStore.user),
            });
            amplitudeActions.track("Click", {
                location: "Home",
                type: "Company Search",
                user: toJS(userAccountDataStore.user),
            });
        }
        if (userAccountDataStore.accessExpiresInDays <= 0) {
            alert('Your access has expired. Please contact support for assistance.');
            return;
        }
        // open a new tab
        window.open('/company/search', '_blank');
    };

    const accessListForUniverse = (universe) => {
        let accessList = [];
        if (universe.client_ids !== null) {
            accessList = universe.client_ids.split(',');
        }
        // remove the current user from the list
        accessList = accessList.filter(email => email.trim() !== user.email);
        // remove admin from the list
        accessList = accessList.filter(email => email.trim() !== 'admin@telemetryllc.com');

        if (accessList.length === 0) {
            return 'Only me';
        }

        return accessList;
    };


    useEffect(() => {
        // check if user data is present
        if (userAccountDataStore.user === null) {
            // user is not logged in, redirect to login page
            console.log('userAccountDataStore user is null');
            navigate('/login');
        }
        else {
            // user data is present but refresh to fetch the latest data
            const refreshUserData = async () => {
                if (fromLogin) {
                    // user just logged in, no need to refresh user data
                    setUser(toJS(userAccountDataStore.user));
                    return;
                }
                
                setUserDataLoading(true);
                try {
                    await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                    setUser(toJS(userAccountDataStore.user));
                } catch (err) {
                    console.log(err);
                }
                finally {
                    // set access expires div
                    if (userAccountDataStore.accessExpiresInDays > 0 && userAccountDataStore.accessExpiresInDays <= 15) {
                        document.getElementById('access-expires').innerHTML = `<span style="color: red">Access expires in ${userAccountDataStore.accessExpiresInDays} days. </span>`;
                    }
                    else if (userAccountDataStore.accessExpiresInDays <= 0) {
                        document.getElementById('access-expires').innerHTML = `<span style="color: red">Access expired. Contact <a href="mailto:support@telmetry-llc.com">support</a> for assistance.</span>`;
                    } 
                    else {
                        document.getElementById('access-expires').innerHTML = '';
                    } 
                    setUserDataLoading(false);
                }
            };

            refreshUserData();
        }
    }, []);

    useEffect(() => {
        if (user !== null) {
            setIsLoading(true);
            const getUniversesAndReceentCompanies = async (user) => {
                setUniverseLoading(true);
                setSharedUniverseLoading(true);
                try {
                    // await all calls to finish
                    await Promise.all([
                        universeListStore.fetchUniverseList(user.email), 
                        universeListStore.fetchSharedUniverseList(user.email),
                        recentCompaniesStore.fetchRecentCompanies(user.uuid),
                    ]);
        
                    if (universeListStore.unAuthorized || recentCompaniesStore.unAuthorized) {
                        // redirect to login page
                        console.log('unAuthorized');
                        navigate('/login');
                        return;
                    }
                    setUniverses(toJS(universeListStore.universes[user.email]));
                    setSharedUniverses(toJS(universeListStore.sharedUniverses[user.email]));
                    if (recentCompaniesStore.companies[user.uuid].length > 1) {
                        setRecentCompanies(toJS(recentCompaniesStore.companies[user.uuid]));
                    }
        
                } catch (err) {
                    console.log(err);
                }
                finally {
                    setUniverseLoading(false);
                    setSharedUniverseLoading(false);
                    setIsLoading(false);
                }
            };

            getUniversesAndReceentCompanies(user);
            // getRecentCompanies(user.uuid);
        }
    }, [user]);

    return (
        <div className="home-container">
            <div className="main-content">
                {isModalVisible && 
                    (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={() => setIsModalVisible(false)}>&times;</span>
                            <h2>Share Collections</h2>
                            <ul style={{ listStyleType: "none" }}>
                                {Object.entries(selectedUniverses)
                                .filter(([_, isSelected]) => isSelected)
                                .map(([id, _]) => (
                                    <li key={id}>{universes.find(u => u.universe_id === id).universe_display_name}</li>
                                ))}
                            </ul>
                            <input
                                type="text"
                                value={shareEmails}
                                onChange={handleShareEmailInputChange}
                                onBlur={() => {if(shareEmails.trim() === "") {setShareEmails("Enter up to 5 emails here separated by a comma")}}}
                                onFocus={() => {setShareEmails("")}}
                                style={{ fontSize: '16px', padding: '20px', width: '400px', borderRadius: '10px', marginTop: '10px' }} 
                            />
                            <br/>
                            <br/>
                            <button className='button' onClick={shareUniverses} disabled={shareUniverseProcessing}>Share</button>
                            <br/>
                            {
                                shareUniverseProcessing && (
                                    <div>
                                        <br/>
                                        <LoadingWithText texts={['Sharing collections...', 'Notifying the recipients...', 'Finished...']} interval={2000}/>
                                    </div>
                                )
                            }
                                    
                        </div>
                    </div>
                    )
                }
                <div id="access-expires" style={{textAlign: 'right', marginRight: '5px'}}></div>
                <div className="section" id="explore">
                    <h2>Explore</h2>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{ display: 'flex-start', marginRight: '100px'}}>
                            <div className="button-group">
                                <button type="button" onClick={handleCompanySearchClick}>Company Search</button>
                            </div>
                        </div>
                        
                        { user === null || isLoading 
                            ? (
                                <div>
                                    <LoadingWithText texts={['Fetching recently viewed companies...']} interval={5000}/>
                                </div>
                            )
                            : (
                                <div style={{display: 'flex-end', marginLeft: '200px'}}>
                                    {/* Map through the recentCompanies array and create a lis item for each universe */}

                                    <span style={{fontWeight: 'bold'}}>Recently Viewed Companies</span>
                                    { recentCompanies.length === 0 
                                        ? (
                                            <div style={{ marginLeft: '10px'}}>No companies</div>
                                        )
                                        : (
                                            <div className="button-group">
                                                <ul>
                                                    {
                                                        recentCompanies.map((item, index) => {
                                                            return (
                                                                <li key={index} style={{textAlign: 'left'}}>
                                                                    <a target='blank' href={`/company/growth?company_id=${item.company_id}`}>{item.company_name}</a>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                     }
                                </div>
                            )
                        }
                        
                    </div>
                </div>
                {/* <div className="section" id="order">
                    <h2>Order</h2>
                    <div className="button-group">
                        <button type="button">Benchmark Report</button>
                        <button type="button" style={{width: '230px'}}>Company Sourcing</button>
                        <button type="button">Talent Sourcing</button>
                    </div>
                </div> */}
                { (universeLaoding && sharedUniverseLoading) || user === null || userDataLoading
                    ? (
                        <div>
                            <br/>
                            <LoadingWithText texts={['Fetching collections...']} interval={5000}/>
                        </div>
                    )
                    : (
                        <div className="home-table-container">
                            <br/>
                            <h2>
                                Library
                            </h2>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{display: 'flex-start', marginRight: '20px', textAlign: 'center', width: '40%'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div style={{alignContent: 'flex-start', width: '75%'}}>
                                            <h3> My Collections </h3>
                                        </div>
                                        <div>
                                            {Object.values(selectedUniverses).some(isSelected => isSelected) && (
                                                <img src={`${process.env.PUBLIC_URL}/share_ico.png`} alt="Share" onClick={openModal}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex-end', marginLeft: '20px', textAlign: 'center', width: '40%'}}>
                                    <h3> Shared With Me </h3>
                                </div>
                            </div>
                            <div style={{display:'flex', alignContent: 'center', justifyContent: 'center'}}>
                                <div style={{display: 'flex-start', marginRight: '20px', textAlign: 'center', width: '40%'}}>
                                    {/* Table to display universes */}
                                    { universeListStore.universes === null || universeListStore.universes[user.email] === undefined || universeListStore.universes[user.email].length === 0
                                        ? (
                                            <div style={{ marginLeft: '10px'}}>No universes</div>
                                        )
                                        : (
                                            <div>
                                                <table className='home-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Created</th>
                                                            <th>Share</th>
                                                            <th>Collection Name</th>
                                                            <th>Shared with</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItems.map((universe, index) => (
                                                            <tr key={index}>
                                                                {/* ... table row contents ... */}
                                                                <td style={{width: '220px'}}>{universe.created_at}</td>
                                                                <td>
                                                                    <input 
                                                                        type="checkbox"
                                                                        checked={selectedUniverses[universe.universe_id] || false}
                                                                        onChange={(e) => handleCheckboxChange(universe.universe_id, e.target.checked)}
                                                                    />
                                                                </td>
                                                                <td style={{width: '220px'}}><a target='_blank' href={`/universe/summary?universe_id=${universe.universe_id}`}>{universe.universe_display_name}</a></td>
                                                                <td>{accessListForUniverse(universe)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <br/>
                                                <div className="pagination-controls">
                                                    {[...Array(pageCount).keys()].map(number => (
                                                        <button 
                                                            key={number} 
                                                            onClick={() => setCurrentPage(number + 1)}
                                                            className={currentPage === number + 1 ? 'active' : ''}
                                                        >
                                                            {number + 1}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div style={{display: 'flex-end', marginLeft: '20px', textAlign: 'center', width: '40%'}}>
                                    {/* <h3> Shared With Me </h3> */}
                                    {/* Table to display universes */}
                                    { universeListStore.sharedUniverses === null || universeListStore.sharedUniverses[user.email] === undefined || universeListStore.sharedUniverses[user.email].length === 0
                                        ? (
                                            <div style={{ marginLeft: '10px'}}>No shared collections</div>
                                        )
                                        : (
                                            <div>
                                                <table className='home-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Created</th>
                                                            <th>Collection Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentItemsSharedUniverses.map((universe, index) => (
                                                            <tr key={index}>
                                                                {/* ... table row contents ... */}
                                                                <td>{universe.created_at}</td>
                                                                <td><a target='_blank' href={`/universe/summary?universe_id=${universe.universe_id}`}>{universe.universe_display_name}</a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <br/>
                                                <div className="pagination-controls">
                                                    {[...Array(pageCountSharedUniverses).keys()].map(number => (
                                                        <button 
                                                            key={number} 
                                                            onClick={() => setCurrentPageSharedUniverses(number + 1)}
                                                            className={currentPageSharedUniverses === number + 1 ? 'active' : ''}
                                                        >
                                                            {number + 1}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
});

export default HomeComponent;
