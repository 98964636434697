import Highcharts from 'highcharts';
import pptxgen from "pptxgenjs";
import html2canvas from 'html2canvas';
import Select from "react-select";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { growthDataStore } from "../../stores/growth_data_store";
import { teamsGrowthDataStore } from "../../stores/teams_growth_data_store";
import { tenureDataStore } from "../../stores/tenure_data_store";
import { talentInflowAndOutflowDataStore } from "../../stores/talent_inflow_outflow_data_store";
import LoadingWithText from "../common/loading_text";
import { universeSummaryDataStore } from "../../stores/universe_summary_data_store";
import { set, toJS } from "mobx";
import { lineColors } from '../../utils/constants';

import { add_chart_slide, add_contacts_slide, add_initial_slide, captureChart } from '../../utils/ppt_gen';
import { ProgressModal } from '../common/progress_modal';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { putCommasInNumber } from '../../utils/utils';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';


const BizDevReport = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');

    const [startYearOptions, setStartYearOptions] = useState([]);
    const [endYearOptions, setEndYearOptions] = useState([]);
    const [startYear, setStartYear] = useState(0);
    const [endYear, setEndYear] = useState(0);
    const [applyTimeFilter, setApplyTimeFilter] = useState(false);

    const [universeSummaryData, setUniverseSummaryData] = useState(null);
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);
    const [growthData, setGrowthData] = useState(null);
    const [teamsGrowthData, setTeamsGrowthData] = useState(null); 
    const [tenureData, setTenureData] = useState(null);
    const [talentFlowData, setTalentFlowData] = useState(null);

    const [totalInflow, setTotalInflow] = useState(0);
    const [totalOutflow, setTotalOutflow] = useState(0);
    const [privateInflow, setPrivateInflow] = useState(0);
    const [pendingOutflow, setPendingOutflow] = useState(0);
    const [totalFemaleInflowProb, setTotalFemaleInflowProb] = useState(0);
    const [totalFemaleOutflowProb, setTotalFemaleOutflowProb] = useState(0);

    const [dataLoading, setDataLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const universeId = searchParams && searchParams.get('universeId') ? searchParams.get('universeId') : null;
    const universeName = searchParams && searchParams.get('universeName') ? searchParams.get('universeName') : null;
    const targetCompanyName = searchParams && searchParams.get('targetCompanyName') ? searchParams.get('targetCompanyName') : null;
    const targetCompanyId = searchParams && searchParams.get('targetCompanyId') ? searchParams.get('targetCompanyId') : null;
    const dataset = searchParams && searchParams.get('datasetName') ? searchParams.get('datasetName') : null;
    const accessToken = searchParams && searchParams.get('accessToken') ? searchParams.get('accessToken') : null;
    const dataVersionDate = searchParams && searchParams.get('dataVersionDate') ? searchParams.get('dataVersionDate') : null;


    useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
                const refreshUserData = async () => {
                    try {
                        await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
                        if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                refreshUserData();
                // check if access is expired
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);

    useEffect(() => {
       
        // check if any of the required parameters are missing
        if (!universeId || !universeName || !targetCompanyName || !targetCompanyId || !dataset) {
            console.log("Missing required parameters");
            alert("Missing required parameters, redirecting to home page...")
            // redirect to the home page
            navigate("/home");
            
            return;
        }
        else {
            if ((accessToken !== null) || (userAccountDataStore.user !== null && userAccountDataStore.accessExpiresInDays > 0)) {
                // get data from the server
                getAndSetData();
            }
        }
    }, [universeId, universeName, targetCompanyName, targetCompanyId, accessToken]);

    useEffect(() => {
        try {
            // setup universe chart
            if (dataLoading === false 
                && document.getElementById('universe-chart-container') !== null
                && universeSummaryData !== null
            ) {
                let universeChartOptions = getUniverseChartOptions();
                const universeChart = Highcharts.chart('universe-chart-container', universeChartOptions);
                if (selectedCompanyIndex !== null) {
                    universeChart.series[0].data[selectedCompanyIndex].select();
                }
                
                // build table for universe summary
                let universeSummaryTable = document.getElementById('universe-summary-table');
                if (universeSummaryTable !== null) {
                    let tableHtml = '';
                    // add header
                    tableHtml += '<tr>';
                    tableHtml += '<th>Company</th>';
                    tableHtml += '<th>Public Profiles</th>';
                    tableHtml += '</tr>';
                    for (let i=0; i<universeSummaryData.length; i++) {
                        tableHtml += '<tr>';
                        tableHtml += '<td>' + universeSummaryData[i]['company_name'] + '</td>';
                        tableHtml += '<td>' + putCommasInNumber(universeSummaryData[i]['employees_count_public']) + '</td>';
                        tableHtml += '</tr>';
                    }
                    universeSummaryTable.innerHTML = tableHtml;
                }

            }
        }
        catch (error) {
            console.log("Error setting up universe chart: ", error);
        }
    }, [universeSummaryData, selectedCompanyIndex]);

    useEffect(() => {
        try {
            // setup growth chart
            if (dataLoading === false 
                && document.getElementById('growth-chart-container') !== null
                && growthData !== null
            ) {
                let growthChartOptions = getOptionsGrowth();
                const growthChart = Highcharts.chart('growth-chart-container', growthChartOptions);
            }
            
            if (dataLoading === false 
                && document.getElementById('total-headcount-chart-container') !== null
                && growthData !== null
            ) {
                let totalHeadcountChartOptions = getOptionsTotalHeadcount();
                const totalHeadcountChart = Highcharts.chart('total-headcount-chart-container', totalHeadcountChartOptions);
            }

            if (dataLoading === false 
                && document.getElementById('hiring-attrition-line-chart-container') !== null
                && growthData !== null
            ) {
                let hiringAttritionLineChartOptions = getOptionsHiringAttritonLine();
                const hiringAttritionLineChart = Highcharts.chart('hiring-attrition-line-chart-container', hiringAttritionLineChartOptions);
            }

            if (dataLoading === false 
                && document.getElementById('hiring-attrition-column-chart-container') !== null
                && growthData !== null
            ) {
                let hiringAttritionColumnsChartOptions = getOptionsHiringAttritonColumn();
                const hiringAttritionLineColumn = Highcharts.chart('hiring-attrition-column-chart-container', hiringAttritionColumnsChartOptions);
            }
        }
        catch (error) {
            console.log("Error setting up growth chart: ", error);
        }

    }, [growthData, startYear, endYear]);

    useEffect(() => {
        try {
            // setup teams growth chart
            if (dataLoading === false 
                && document.getElementById('headcount-by-department-chart-container') !== null
                && teamsGrowthData !== null
            ) {
                let headcountByDepartmentChartOptions = getHeadcountByDepartmentOptions();
                const headcountByDepartmentChart = Highcharts.chart('headcount-by-department-chart-container', headcountByDepartmentChartOptions);

                // build a map of each department and its headcount for the
                // month of january of each year
                let departmentHeadcountMap = {
                    'Sales & Marketing': {},
                    'Product & Engineering': {},
                    'Operations & Customer Support': {},
                    'Finance, HR, Administrative & IT': {},
                    'Other': {},
                    'All': {},
                };

                let deptDistMap = {};

                let dataYears = new Set();
                
                for (let i=0; i<teamsGrowthData.length; i++) {
                    
                    let date = new Date(teamsGrowthData[i]['the_date']);
                    // get the UTC date
                    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
                    if (date.getUTCMonth() !== 0) {
                        continue;
                    }
                    // filter for All geo1 and All seniority
                    if (teamsGrowthData[i]['geo1'] !== 'All' || teamsGrowthData[i]['seniority'] !== 'All') {
                        continue;
                    }
                    // filter out All dept
                    let department = teamsGrowthData[i]['department'];
                    let year = date.getUTCFullYear();
                    dataYears.add(year);
                    if (department == 'All') {
                        if (!departmentHeadcountMap['All'].hasOwnProperty(year)) {
                            departmentHeadcountMap['All'][year] = 0;
                        }
                        departmentHeadcountMap['All'][year] += teamsGrowthData[i]['total_headcount'];
                    }
                    else if (department === "Sales" || department === "Marketing") {
                        if (!departmentHeadcountMap['Sales & Marketing'].hasOwnProperty(year)) {
                            departmentHeadcountMap['Sales & Marketing'][year] = 0;
                        }
                        departmentHeadcountMap['Sales & Marketing'][year] += teamsGrowthData[i]['total_headcount'];
                        
                    }
                    else if (department === "Product" || department === "Engineering") {
                        if (!departmentHeadcountMap['Product & Engineering'].hasOwnProperty(year)) {
                            departmentHeadcountMap['Product & Engineering'][year] = 0;
                        }
                        departmentHeadcountMap['Product & Engineering'][year] += teamsGrowthData[i]['total_headcount'];
                    }
                    else if (department === "Operations" || department === "Customer Support") {
                        if (!departmentHeadcountMap['Operations & Customer Support'].hasOwnProperty(year)) {
                            departmentHeadcountMap['Operations & Customer Support'][year] = 0;
                        }
                        departmentHeadcountMap['Operations & Customer Support'][year] += teamsGrowthData[i]['total_headcount'];
                    }
                    else if (department === "Finance" || department === "HR" || department === "Administrative" || department === "IT") {
                        if (!departmentHeadcountMap['Finance, HR, Administrative & IT'].hasOwnProperty(year)) {
                            departmentHeadcountMap['Finance, HR, Administrative & IT'][year] = 0;
                        }
                        departmentHeadcountMap['Finance, HR, Administrative & IT'][year] += teamsGrowthData[i]['total_headcount'];
                    }
                    else {
                        if (!departmentHeadcountMap['Other'].hasOwnProperty(year)) {
                            departmentHeadcountMap['Other'][year] = 0;
                        }
                        departmentHeadcountMap['Other'][year] += teamsGrowthData[i]['total_headcount'];
                    }
                }


                //convert the set to an array
                dataYears = Array.from(dataYears);
        
                // calculate the % dist
                for (let dept in departmentHeadcountMap) {
                    for (let year of dataYears) {
                        if (!deptDistMap.hasOwnProperty(dept)) {
                            deptDistMap[dept] = {};
                        }
                        if (!deptDistMap[dept].hasOwnProperty(year)) {
                            deptDistMap[dept][year] = 0;
                        }

                        deptDistMap[dept][year] = (departmentHeadcountMap[dept][year] / departmentHeadcountMap['All'][year]) * 100;
                    }
                }
                

                // build the table with years as columns
                let headcountByDeptTable = document.getElementById('headcount-by-department-table');
                if (headcountByDeptTable !== null) {
                    let tableHtml = '';
                    // add header
                    tableHtml += '<tr>';
                    tableHtml += '<th>Department</th>';
                    for (let year of dataYears) {
                        tableHtml += '<th>' + year + '</th>';
                    }
                    tableHtml += '</tr>';
                    for (let dept in deptDistMap) {
                        if (dept === 'Other' || dept === 'All') {
                            continue;
                        }
                        tableHtml += '<tr>';
                        tableHtml += '<td>' + dept + '</td>';
                        for (let year of dataYears) {
                            tableHtml += '<td>' + parseFloat(deptDistMap[dept][year]).toFixed(1) + '%</td>';
                        }
                        tableHtml += '</tr>';
                    }
                    headcountByDeptTable.innerHTML = tableHtml;
                }

            }
        }
        catch (error) {
            console.log("Error setting up teams growth chart: ", error);
        }
    }, [teamsGrowthData, startYear, endYear]);

    useEffect(() => {
        try {
            // setup tenure chart
            if (dataLoading === false
                && document.getElementById('company-tenure-dist-chart-container') !== null
                && document.getElementById('career-tenure-dist-chart-container') !== null
                && tenureData !== null
            ) {
                const companyTenureChartOptions = getCompanyTenureChartOptions();
                Highcharts.chart('company-tenure-dist-chart-container', companyTenureChartOptions);

                const careerTenureChartOptions = getCareerTenureChartOptions();
                Highcharts.chart('career-tenure-dist-chart-container', careerTenureChartOptions);
            }
        }
        catch (error) {
            console.log("Error setting up tenure chart: ", error);
        }
    }, [tenureData, startYear, endYear]);

    useEffect(() => {
        try {
            // setup talent flow chart
            if (dataLoading === false
                && document.getElementById('talent-flow-chart-container') !== null
                && talentFlowData !== null
            ) {
                const sankeyChartOptions = getSankeyChartOptions();
                Highcharts.chart('talent-flow-chart-container', sankeyChartOptions);
            }
        }
        catch (error) {
            console.log("Error setting up talent flow chart: ", error);
        }
    }, [talentFlowData]);


    const getAndSetData = async () => {
        // get data from the server
        console.log("Getting data from the server");
        setDataLoading(true);

        const dataVersionDateFormat = new Date(dataVersionDate);
        const endDate = `${dataVersionDateFormat.getFullYear()}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;
        const startDate = `${dataVersionDateFormat.getFullYear() - 10}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`;
        const startDateForTalentFlow = `${dataVersionDateFormat.getFullYear() - 3}-${dataVersionDateFormat.getMonth() + 1}-${dataVersionDateFormat.getDate()}`
        
        let start = dataVersionDateFormat.getFullYear() - 10;
        let end = dataVersionDateFormat.getFullYear();
        setStartYear(start);
        setEndYear(end);

        // set startYearOptions and endYearOptions
        let startYearValues = [];
        let endYearValues = [];
        for (let i=start; i<=end; i++) {
            startYearValues.push(i);
            endYearValues.push(i);
        }
        setStartYearOptions(startYearValues);
        setEndYearOptions(endYearValues);
        
        // create a Promise array to fetch data for all the charts
        let promises = [
            universeSummaryDataStore.fetchUniverseSummaryData(universeId, accessToken),
            growthDataStore.fetchGrowthData(targetCompanyId, null, accessToken, dataset),
            teamsGrowthDataStore.fetchTeamsGrowthData(targetCompanyId, accessToken, false, dataset, null),
            tenureDataStore.fetchTenureData(targetCompanyId, null, accessToken, startDate, endDate, [], [], [], dataset),
            talentInflowAndOutflowDataStore.fetchTalentFlowData(targetCompanyId, accessToken, startDateForTalentFlow, endDate, [], [], [], dataset)
        ];

        // wait for all the promises to resolve
        await Promise.all(promises);

        // set data in the state
        setUniverseSummaryData(toJS(universeSummaryDataStore.data[universeId]));
        setGrowthData(toJS(growthDataStore.data[targetCompanyId]));
        setTeamsGrowthData(toJS(teamsGrowthDataStore.data[targetCompanyId]));
        setTenureData(toJS(tenureDataStore.data[targetCompanyId]));
        setTalentFlowData(toJS(talentInflowAndOutflowDataStore.data[targetCompanyId]));

        setDataLoading(false);
    };

    const getUniverseChartOptions = () => {
        const seriesData = [];
        let dataMapper = {};
        let showMedian = true;
        
        if (!universeSummaryData || universeSummaryData.length === 0) {
            console.log("No universe data found");
            return null;
        }

        for (let i=0; i<universeSummaryData.length; i++) {
            let x = parseFloat((universeSummaryData[i]['ltm_retention_rate'] * 100).toFixed(1));  
            let y = parseFloat((universeSummaryData[i]['ltm_net_hc_growth'] * 100).toFixed(1));
            
            seriesData.push(
                [x, y]
            );

            let xy = x.toString() + ',' + y.toString();
            
            dataMapper[xy] = {
                index: i,
                companyName: universeSummaryData[i].company_name,
            };
            if (universeSummaryData[i].company_name === targetCompanyName) {
                setSelectedCompanyIndex(i);
            }
        };

        const annotationLables = seriesData.map((item) => {
            return {
                point: {
                    x: item[0],
                    y: item[1],
                    xAxis: 0,
                    yAxis: 0, 
                },
                text: dataMapper[item[0].toString() + ',' + item[1].toString()]['companyName'],

            };
        });

        let options = {
            annotations: [{
                labels: annotationLables,
                labelOptions: {
                    shape: 'connector',
                    align: 'right',
                    justify: false,
                    crop: true,
                    style: {
                        fontSize: '0.8em',
                    },
                    allowOverlap: true,
                },
            }],
            chart: {
                type: 'scatter',
                //height: '40%',
                zoomType: 'xy',
                width: 1280,
                height: 720,
            },
            title: {
                text: universeName,
                align: 'left',
            },
            subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
            xAxis: {
                title: {
                    text: 'Employee Retention',
                    style: {
                        fontSize: '20px',
                    }
                },
                labels: {
                    formatter: function() {
                            return this.value + '%';
                    },
                    style: {
                        fontSize: '20px',
                    },
				},
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: 'Net Headcount Growth',
                    style: {
                        fontSize: '20px',
                    }
                },
                labels: {
					format: '{value}%',
                    style: {
                        fontSize: '20px'
                    }
				},
                gridLineWidth: 1,
            },
            series: [
                {
                    name: 'Collection Summary',
                    data: seriesData,
                    marker: {
                        symbol: 'circle',
                        states: {
                            select: {
                                fillColor: 'coral',
                                radius: 10,
                                lineWidth: 0,
                            },
                        },
                    },
                },
            ],
            tooltip: {
                formatter: function() {
                    // check if dataMapper is present
                    if (Object.keys(dataMapper).length === 0) {
                        return;
                    }
                    else {
                        let key = this.x + ',' + this.y;
                        // check if key is present in dataMapper
                        if (!dataMapper.hasOwnProperty(key)) {
                            return;
                        }
                        let companyName = dataMapper[this.x + ',' + this.y]['companyName'];
                        let xLabel = 'Employee Retention'
                        let yLabel = 'Net Headcount Growth'

                        let label = `<b>${companyName}</b>` + '<br/>' + xLabel + ': ' + this.x +  '%' + '<br/>' +
                                    yLabel + ': ' + this.y + '%';

                        return label;
                    }
                },               
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 10
                    },
                    // point: {
                    //     events: {
                    //         click: function (event) {
                    //             let companyName = dataMapper[this.x + ',' + this.y]['companyName'];
                    //             setCompanySummary(companyName);
                    //             setSelectedCompany(companyName);
                    //         }
                    //     }
                    // },
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
            },
        };

        if (showMedian) {
            const medians = calculateMedian(seriesData);

            // Calculating the min and max values from your data
            const xValues = seriesData.map(point => point[0]);
            const yValues = seriesData.map(point => point[1]);

            const xMin = Math.min(...xValues);
            const xMax = Math.max(...xValues);
            const yMin = Math.min(...yValues);
            const yMax = Math.max(...yValues);

            // Adding the median lines
            options.series.push(
                {
                    type: 'spline',
                    name: `Median Employee Retention`,
                    data: [[xMin, medians[1]], [xMax, medians[1]]],
                    color: 'green',
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: false,
                    dashStyle: 'Dash',
                },
                {
                    type: 'spline',
                    name: `Median Net Headcount Growth`,
                    data: [[medians[0], yMin], [medians[0], yMax]],
                    color: 'green',
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: false,
                    dashStyle: 'Dash',
                }
            );

        };

        return options;
    };

    const calculateMedian = (seriesData) => {
        const xSorted = seriesData.map(item => item[0]).sort((a, b) => a - b);
        const mid = Math.floor(xSorted.length / 2);
        let xMedian = xSorted[mid];
        if (xSorted.length % 2 === 0) {
            xMedian = (xSorted[mid - 1] + xSorted[mid]) / 2;
        }
        
        const ySorted = seriesData.map(item => item[1]).sort((a, b) => a - b);
        const yMid = Math.floor(ySorted.length / 2);
        let yMedian = ySorted[yMid];
        if (ySorted.length % 2 === 0) {
            yMedian = (ySorted[yMid - 1] + ySorted[yMid]) / 2;
        }

        return [xMedian, yMedian];
    };

    const getOptionsGrowth = () => {
        if (!growthData || growthData.length === 0) {
            console.log("No growth data found");
            return null;
        }

        const filteredData = growthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            return item.geo1 === 'All' && itemDate.getUTCFullYear() >= startYear && itemDate.getUTCFullYear() <= endYear;
        });
    
		const ltmHeadcountGrowthData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_net_hc_growth * 100).toFixed(1))];
		});
		
        const ltmAvgNetHCGrowthBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_net_hc_growth_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        
        const dataSeries = [
            {
                type: 'spline',
                name: 'Net Headcount Growth (Last 12 months)',
                data: ltmHeadcountGrowthData,
                color: lineColors['Net Headcount'],
            },
            {
                type: 'spline',
                name: 'Benchmark: Avg Net HC Growth',
                data: ltmAvgNetHCGrowthBenchmarkData,
                color: lineColors['Net Headcount'],
                dashStyle: 'Dash',
            }
        ];

		return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Headcount Metrics',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    },
                },
			},
			yAxis: {
				title: {
					text: 'Rate (Last 12 months)',
                    style: {
                        fontSize: '20px',
                    }
				},
				labels: {
					format: '{value}%',
                    style: {
                        fontSize: '20px',
                    }
				}
			},
			legend: {
				enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        // events: {
                        //     click: function (event) {
                        //         let date = new Date(event.point.x);
                        //         let dateStr = '' + date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
                        //         setAdditionAttritionDate(new Date(dateStr));
                        //     }
                        // }
                    },
                    events: {
                        // afterAnimate: function() {
                        //     if (!displayBenchmark) {
                        //         let chart = this.chart;
                        //         chart.renderer.text('Benchmark data not available', 1100, 25)
                        //         .css({
                        //             fontSize: '14px',
                        //             color: 'gray',
                        //         })
                        //         .add();
                        //     }
                        // }
                    }
                },
            },
	
		};
		
	};

    const getOptionsTotalHeadcount = () => {
        if (!growthData || growthData.length === 0) {
            console.log("No growth data found");
            return null;
        }

        const filteredData = growthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            return item.geo1 === 'All' && itemDate.getUTCFullYear() >= startYear && itemDate.getUTCFullYear() <= endYear;
        });
        const totalHeadcountData = filteredData.map((item) => {
            return [item.the_date, item.total_headcount];
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '20%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            tooltip: {
                shared: true,
            },
			title: {
				text: 'Total Headcount (Public Profiles)',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    }
                },
			},
			yAxis: {
                title: {
                    text: 'Total Headcount',
                    style: {
                        fontSize: '20px',
                    }
                },
                labels: {
                    // format: '{value}',
                    formatter: function() {
                        if(this.value >= 1000000000){
                            return (this.value / 1000000000) + 'B';
                        }
                        else if(this.value >= 1000000) {
                            return (this.value / 1000000) + 'M';
                        }
                        else if(this.value >= 1000) {
                            return (this.value / 1000) + 'K';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '20px',
                    }
                },
                lineWidth: 2,
			},

			legend: {
				enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
			},
			
			series: [
				{
					type: 'column',
					name: 'Total Heacount (Public Profiles)',
					data: totalHeadcountData,
                    color: lineColors['Total Headcount'],
                    pointWidth: 10,
                    tooltip: {
                        // format tooltip to show commas in numbers
                        pointFormatter: function() {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Highcharts.numberFormat(this.y, 0, undefined, ',') + '</b><br/>';
                        }
                    },
                    opacity: 0.8,
				},
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
    };

    const getOptionsHiringAttritonLine = () => {
        if (!growthData || growthData.length === 0) {
            console.log("No growth data found");
            return null;
        }

        const filteredData = growthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            return item.geo1 === 'All' && itemDate.getUTCFullYear() >= startYear && itemDate.getUTCFullYear() <= endYear;
        });
    
		const ltmEmployeeAdditionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_addition_rate * 100).toFixed(1))];
		});
		const ltmEmployeeAttritionData = filteredData.map((item) => {
			return [item.the_date, parseFloat((item.ltm_attrition_rate * 100).toFixed(1))];
		});
        const ltmAvgAttritionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_attrition_rate_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const ltmAvgAdditionRateBenchmarkData = filteredData.map((item) => {
            return [item.the_date, parseFloat((item.avg_addition_rate_prev_12_mo_benchmark * 100).toFixed(1))];
        });
        const dataSeries = [
            {
                type: 'spline',
                name: 'Hiring Rate (Last 12 months)',
                data: ltmEmployeeAdditionData,
                color: lineColors['Hiring'],
            },
            {
                type: 'spline',
                name: 'Attrition Rate (Last 12 months)',
                data: ltmEmployeeAttritionData,
                color: lineColors['Attrition'],
            },
            {
                type: 'spline',
                name: 'Benchmark: Avg Hiring Rate',
                data: ltmAvgAdditionRateBenchmarkData,
                color: lineColors['Hiring'],
                dashStyle: 'Dash',
            },
            {
                type: 'spline',
                name: 'Benchmark: Avg Attrition Rate',
                data: ltmAvgAttritionRateBenchmarkData,
                color: lineColors['Attrition'],
                dashStyle: 'Dash',
            },
        ];

		return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '33.3%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Headcount Metrics',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    }
                },
			},
			yAxis: {
				title: {
					text: 'Rate (Last 12 months)',
                    style: {
                        fontSize: '20px',
                    }
				},
				labels: {
					format: '{value}%',
                    style: {
                        fontSize: '20px',
                    },
				}
			},
			legend: {
				enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
			},
			
            series: dataSeries,

			credits: {
				enabled: false
			},
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                shared: true,
                crosshairs: true, 
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
		
	};

    const getOptionsHiringAttritonColumn = () => {
        if (!growthData || growthData.length === 0) {
            console.log("No growth data found");
            return null;
        }

        const filteredData = growthData.filter((item) => {
            let itemDate = new Date(item.the_date);
            return item.geo1 === 'All' && itemDate.getUTCFullYear() >= startYear && itemDate.getUTCFullYear() < endYear;
        });
        const additionData = filteredData.map((item) => {
            let growthPct = item.new_headcount/item.total_headcount;
            return [item.the_date, parseFloat((growthPct * 100).toFixed(1))];
        });
        const attritionData = filteredData.map((item) => {
            let attritionPct = item.lost_headcount/item.total_headcount;
            return [item.the_date, parseFloat((-attritionPct * 100).toFixed(1))];
        });

        return {
			chart: {
				zoomType: 'xy',
                type: 'spline',
                height: '20%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            tooltip: {
                shared: true,
            },
			title: {
				text: 'Hiring and Attritions',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    }
                },
			},
			yAxis: {
                title: {
                    text: 'Monthly Hiring and Attrition Rates',
                    style: {
                        fontSize: '20px',
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        fontSize: '20px',
                    }
                },
                lineWidth: 2,
			},

			legend: {
				enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
			},
			
			series: [
				{  
                    type: 'column',
                    name: 'Hiring',
                    data: additionData,
                    color: lineColors['Hiring'],
                    pointWidth: 10,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
                {
                    type: 'column',
                    name: 'Attrition',
                    data: attritionData,
                    color: lineColors['Attrition'],
                    pointWidth: 10,
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
                    },
                },
			],

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                },
            },
	
		};
    };

    const getHeadcountByDepartmentOptions = () => {
        if (!teamsGrowthData || teamsGrowthData.length === 0) {
            console.log("No teams growth data found");
            return null;
        }

        let selectedRegionValues =['All']
        // let selectedDepartmentValues = selectedDepartments.map(department => department.value);
        let selectedSeniorityValues = ['All']
        let filteredTeamsDepartmentData = teamsGrowthData.filter((item) => {
            let itemDate = new Date(item.the_date);

            return selectedRegionValues.includes(item.geo1) 
                    && selectedSeniorityValues.includes(item.seniority) 
                    && item.department !== 'All' // Filtering out 'All' here
                    && itemDate.getUTCFullYear() >= startYear
                    && itemDate.getUTCFullYear() <= endYear;
        });

        const deptDataMap = {};
        const dataSeries = [];
        for (let i=0; i<filteredTeamsDepartmentData.length; i++) {
            if (deptDataMap.hasOwnProperty(filteredTeamsDepartmentData[i].department)) {
                const data = [filteredTeamsDepartmentData[i].the_date, filteredTeamsDepartmentData[i].total_headcount];
                deptDataMap[filteredTeamsDepartmentData[i].department].push(data);
                continue;
            }
            deptDataMap[filteredTeamsDepartmentData[i].department] = [];
        };

        const legendOrder = ['All', 'Sales', 'Product'];

        // Sort based on legendOrder, then alphabetically, and 'Unsure' is always last
        const sortedDeptDataMap = Object.entries(deptDataMap)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Unsure') return 1;
                if (keyB === 'Unsure') return -1;
                if (keyA === keyB) return 0;
                if (legendOrder.includes(keyA) && legendOrder.includes(keyB)) {
                    return legendOrder.indexOf(keyA) - legendOrder.indexOf(keyB);
                }
                if (legendOrder.includes(keyA)) return -1;
                if (legendOrder.includes(keyB)) return 1;
                return keyA.localeCompare(keyB);
            })
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        for (let key in sortedDeptDataMap) {
            dataSeries.push({
                name: key,
                data: sortedDeptDataMap[key],
                type: 'column',
                color: lineColors[key],
                pointWidth:20,
                stacking: 'percent',
                //opacity: (selectedDepartmentValues.length ===1 && selectedDepartmentValues[0] === "All") ? 1 : selectedDepartmentValues.includes(key) ? 1 : 0.6,
            });
        };

		return {
			chart: {
				zoomType: 'xy',
                //type: 'column',
                height: '33.33%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
			title: {
				text: 'Total Headcount by Department (Public Profiles)' ,
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    }
                },
			},
			yAxis: {
				title: {
					text: 'Department Distribution',
                    style: {
                        fontSize: '20px',
                    }
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                    style: {
                        fontSize: '20px',
                    }
                },
			},
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
            },
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};
		
	};

    const getCompanyTenureChartOptions = () => {
        if (tenureData === null || tenureData === undefined || tenureData.length === 0) { 
            return {};
        }

        const categoriesMap = {
            '0-1':  [], 
            '1-2':  [], 
            '2-3':  [], 
            '3-4':  [], 
            '4-5':  [], 
            '5-10': [], 
            '10+':  [],
        };
        const dataSeries = [];
        // filter tenureData based on startYear and endYear
        let filteredData = tenureData.filter((item) => {
            let itemDate = new Date(item.the_date);
            if (itemDate.getUTCFullYear() >= startYear && itemDate.getUTCFullYear() <= endYear) {
                return item;
            }
        });

        for (let i = 0; i < filteredData.length; i++) {
            let item = filteredData[i];

            let data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_0_1) * 100).toFixed(1))];
            categoriesMap['0-1'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_1_2) * 100).toFixed(1))];
            categoriesMap['1-2'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_2_3) * 100).toFixed(1))];
            categoriesMap['2-3'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_3_4) * 100).toFixed(1))];
            categoriesMap['3-4'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_4_5) * 100).toFixed(1))];
            categoriesMap['4-5'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_5_10 * 100)).toFixed(1))];
            categoriesMap['5-10'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_company_tenure_10_plus) * 100).toFixed(1))];
            categoriesMap['10+'].push(data);
        }

        for (let key in categoriesMap) {
            dataSeries.push({
                name: key + ' years',
                data: categoriesMap[key],
                color: lineColors['Company Tenure' + key],
                type: 'column',
                stacked: true,
                stacking: 'percent',
                pointWidth: 8,
            });
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            title: {
				text: 'Company Tenure Distribution',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    }
                },
			},
			yAxis: {
				title: {
					text: 'Distribution',
                    style: {
                        fontSize: '20px',
                    }
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                    style: {
                        fontSize: '20px',
                    }
                },
			},
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
            },
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};

    };

    const getCareerTenureChartOptions = () => {
        if (tenureData === null || tenureData === undefined || tenureData.length === 0) { 
            return {};
        }

        const categoriesMap = {
            '0-1':  [], 
            '1-2':  [], 
            '2-3':  [], 
            '3-4':  [], 
            '4-5':  [], 
            '5-10': [], 
            '10+':  [],
        };
        const dataSeries = [];
         // filter tenureData based on startYear and endYear
         let filteredData = tenureData.filter((item) => {
            let itemDate = new Date(item.the_date);
            if (itemDate.getUTCFullYear() >= startYear && itemDate.getUTCFullYear() <= endYear) {
                return item;
            }
        });

        for (let i = 0; i < filteredData.length; i++) {
            let item = filteredData[i];

            let data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_0_1) * 100).toFixed(1))];
            categoriesMap['0-1'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_1_2) * 100).toFixed(1))];
            categoriesMap['1-2'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_2_3) * 100).toFixed(1))];
            categoriesMap['2-3'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_3_4) * 100).toFixed(1))];
            categoriesMap['3-4'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_4_5) * 100).toFixed(1))];
            categoriesMap['4-5'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_5_10 * 100)).toFixed(1))];
            categoriesMap['5-10'].push(data);

            data = [item.the_date, parseFloat(((filteredData[i].pct_career_tenure_10_plus) * 100).toFixed(1))];
            categoriesMap['10+'].push(data);
        }

        for (let key in categoriesMap) {
            dataSeries.push({
                name: key + ' years',
                data: categoriesMap[key],
                color: lineColors['Career Tenure' + key],
                type: 'column',
                stacked: true,
                stacking: 'percent',
                pointWidth: 8,
            });
        }

        return {
			chart: {
				zoomType: 'xy',
                type: 'column',
                height: '25%',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: 10,
                        y: -10,
                    }
                },
			},
            title: {
				text: 'Career Tenure Distribution',
				align: 'left'
			},
			subtitle: {
				text: document.ontouchstart === undefined ?
					'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
				align: 'left'
			},
			xAxis: {
				type: 'datetime',
                tickInterval: 24 * 3600 * 1000 * 365,  // 1 year
                labels: {
                    style: {
                        fontSize: '20px',
                    }
                },
			},
			yAxis: {
				title: {
					text: 'Distribution',
                    style: {
                        fontSize: '20px',
                    }
				},
                labels: {
                    formatter: function() {
                        return this.value + '%';
                    },
                    style: {
                        fontSize: '20px',
                    }
                },
			},
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '20px',
                },
            },
			tooltip: {
                pointFormatter: function() {
                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                },
                shared: true,
            },
			series: dataSeries,

			credits: {
				enabled: false
			},

            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 2,
                    point: {
                        events: {
                            click: function (event) {
                                console.log(event);
                            }
                        }
                    }
                },
            },
	
		};

    };

    const getSankeyChartOptions = () => {
        if (!talentFlowData || talentFlowData.length === 0) {
            console.log("No talent flow data found");
            return null;
        }

        let filteredSankeyInData = talentFlowData.filter((item) => item['dataset'] === 'inflow');
        let filteredSankeyOutData = talentFlowData.filter((item) => item['dataset'] === 'outflow');
        
        let companyEmpInStatsMap = {};
        let totalEmpInCount = 0;
        let privateInflowCount = 0;
        let totalSumFirstNameFemaleProbIn = 0;
        let totalNumGenderProbIn = 0;

        for (let i = 0; i < filteredSankeyInData.length; i++) {
            if (filteredSankeyInData[i]['from_company_name'] === 'Private') {
                privateInflowCount += filteredSankeyInData[i]['employee_count'];
            }
            totalEmpInCount += filteredSankeyInData[i]['employee_count'];
            totalSumFirstNameFemaleProbIn += filteredSankeyInData[i]['sum_first_name_female_prob'];
            totalNumGenderProbIn += filteredSankeyInData[i]['num_gender_prob'];

            // skip if from_company_name is Other
            if (filteredSankeyInData[i]['from_company_name'] === 'Other') {
                continue;
            }
            
            if (filteredSankeyInData[i]['from_company_id'] in companyEmpInStatsMap) {
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['empCount'] += filteredSankeyInData[i]['employee_count'];
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['totalSumFirstNameFemaleProb'] += filteredSankeyInData[i]['sum_first_name_female_prob'];
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']]['totalNumGenderProb'] += filteredSankeyInData[i]['num_gender_prob']
            } else {
                companyEmpInStatsMap[filteredSankeyInData[i]['from_company_id']] = {
                    pastCompanyName: filteredSankeyInData[i]['from_company_name'],
                    empCount: filteredSankeyInData[i]['employee_count'],
                    totalSumFirstNameFemaleProb: filteredSankeyInData[i]['sum_first_name_female_prob'],
                    totalNumGenderProb: filteredSankeyInData[i]['num_gender_prob']
                };
            };
        };
        
        const companyEmpInStatsMapSortedByCount = Object.entries(companyEmpInStatsMap).sort((a, b) => b[1]['empCount'] - a[1]['empCount']);
        
        let companyEmpOutStatsMap = {};
        let totalEmpOutCount = 0;
        let pendingOutflowCount = 0;
        let totalSumFirstNameFemaleProbOut = 0;
        let totalNumGenderProbOut = 0;

        for (let i = 0; i < filteredSankeyOutData.length; i++) {
            if (filteredSankeyOutData[i]['to_company_name'] === 'Pending') {
                pendingOutflowCount += filteredSankeyOutData[i]['employee_count'];
            }
            totalEmpOutCount += filteredSankeyOutData[i]['employee_count'];
            totalSumFirstNameFemaleProbOut += filteredSankeyOutData[i]['sum_first_name_female_prob'];
            totalNumGenderProbOut += filteredSankeyOutData[i]['num_gender_prob'];

            // skip if to_company_name is Other
            if (filteredSankeyOutData[i]['to_company_name'] === 'Other') {
                continue;
            }
            
            if (filteredSankeyOutData[i]['to_company_id'] in companyEmpOutStatsMap) {
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['empCount'] += filteredSankeyOutData[i]['employee_count'];
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['totalSumFirstNameFemaleProb'] += filteredSankeyOutData[i]['sum_first_name_female_prob'];
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']]['totalNumGenderProb'] += filteredSankeyOutData[i]['num_gender_prob']
            } else {
                companyEmpOutStatsMap[filteredSankeyOutData[i]['to_company_id']] = {
                    currentCompanyName: filteredSankeyOutData[i]['to_company_name'], 
                    empCount: filteredSankeyOutData[i]['employee_count'],
                    totalSumFirstNameFemaleProb: filteredSankeyOutData[i]['sum_first_name_female_prob'],
                    totalNumGenderProb: filteredSankeyOutData[i]['num_gender_prob']
                };
            };
        };
        

        const companyEmpOutStatsMapSortedByCount = Object.entries(companyEmpOutStatsMap).sort((a, b) => b[1]['empCount'] - a[1]['empCount']);

        setTotalInflow(totalEmpInCount);
        setTotalOutflow(totalEmpOutCount);
        setPrivateInflow(privateInflowCount);
        setPendingOutflow(pendingOutflowCount);
        setTotalFemaleInflowProb(totalSumFirstNameFemaleProbIn / totalNumGenderProbIn);
        setTotalFemaleOutflowProb(totalSumFirstNameFemaleProbOut / totalNumGenderProbOut);
       
        const sankeyInSeriesData = [];
        const sankeyOutSeriesData = [];

        let count = 0;
        let showGenderProb = false;
        for (let i = 0; i < companyEmpInStatsMapSortedByCount.length; i++) {
            if (companyEmpInStatsMapSortedByCount[i][1]['pastCompanyName'] !== 'Private') {
                let genderProbText = '';
                if (showGenderProb && companyEmpInStatsMapSortedByCount[i][1]['totalNumGenderProb'] >= 10) {
                    genderProbText = ' [' + (companyEmpInStatsMapSortedByCount[i][1]['totalSumFirstNameFemaleProb'] / companyEmpInStatsMapSortedByCount[i][1]['totalNumGenderProb'] * 100).toFixed(0) + '%F]';
                }
                sankeyInSeriesData.push({
                    id: count,
                    from: companyEmpInStatsMapSortedByCount[i][1]['empCount'] + ':' + companyEmpInStatsMapSortedByCount[i][1]['pastCompanyName'] + genderProbText,
                    to: targetCompanyName,
                    weight: companyEmpInStatsMapSortedByCount[i][1]['empCount'],
                    color: '#999999',
                });
            };
            if (count === 25){
                break;
            }
            count += 1;
        };
        
        for (let i = 0; i < companyEmpOutStatsMapSortedByCount.length; i++) {
            if (companyEmpOutStatsMapSortedByCount[i][1]['currentCompanyName'] !== 'Pending') {
                let genderProbText = '';
                if (showGenderProb && companyEmpOutStatsMapSortedByCount[i][1]['totalNumGenderProb'] >= 10) {
                    genderProbText = '[' + (companyEmpOutStatsMapSortedByCount[i][1]['totalSumFirstNameFemaleProb'] / companyEmpOutStatsMapSortedByCount[i][1]['totalNumGenderProb'] * 100).toFixed(0) + '%F] ';
                }
                sankeyOutSeriesData.push({
                    id: count,
                    from: targetCompanyName,
                    to: genderProbText + companyEmpOutStatsMapSortedByCount[i][1]['currentCompanyName'] + ':' + companyEmpOutStatsMapSortedByCount[i][1]['empCount'],
                    weight: companyEmpOutStatsMapSortedByCount[i][1]['empCount'],
                    color: '#999999',
                });
            };
            if (count === 50) {
                break;
            }
            count += 1;
        };

        const sankeyChartSeriesData = sankeyInSeriesData.concat(sankeyOutSeriesData);
        const endDate = new Date(dataVersionDate);
        // set start date to 3 year before end date
        const startDate = new Date(endDate);
        startDate.setUTCFullYear(endDate.getUTCFullYear() - 3);

        // get month in 3-letter format
        const endMonth = endDate.toLocaleString('default', { month: 'short' });
        const startMonth = startDate.toLocaleString('default', { month: 'short' });

        // 
        const startDateLabel = `${startMonth} ${startDate.getUTCFullYear()}`
        const endDateLabel = `${endMonth} ${endDate.getUTCFullYear()}`;

        const dateLabel  = startDateLabel + ' - ' + endDateLabel;
        return {
            chart: {
                //height: '55%',
                width: 1280,
                height: 720,
            },

			title: {
				text: `${targetCompanyName} - Talent Inflow/Outflow (Public Profiles) ${dateLabel}`,
				align: 'center',
			},

            subtitle: {
                text: (sankeyChartSeriesData.length === 0) ? 'No data available for selected filters' : '',
                align: 'center',
                verticalAlign: 'middle',
                style:{
                    fontSize: '20px',
                },
            },
			
			legend: {
				enabled: true,
			},
			
			series: [{
				keys: ['from', 'to', 'weight'],
                data: sankeyChartSeriesData,
                type: 'sankey',
                name: 'Talent Flow',
                // links: links,
                linkOpacity: 0.5,
                // nodes: nodes,
                tooltip: {
                    nodeFormatter: function () {
                        return this.name.split(':')[1];
                    },
                    pointFormatter: function () {
                        if(this.toNode.name === targetCompanyName) {
                            // inflow hover
                            let fromName = this.fromNode.name.split(':')[1];
                            let fromNameWithoutGenderProb = fromName.replace(/\s\[\d+%F\]/, '');
                            return fromNameWithoutGenderProb + ' -> ' + this.toNode.name + ': ' + this.weight;
                        } else {
                            // outflow hover
                            let toName = this.toNode.name.split(':')[0];
                            let toNameWithoutGenderProb = toName.replace(/\[\d+%F]\s/, '');
                            return this.fromNode.name + ' -> ' + toNameWithoutGenderProb + ': ' + this.weight;
                        }
                        //return this.fromNode.name + ' -> ' + this.toNode.name + ': ' + this.weight;
                    }
                },
            }],

            plotOptions: {
                sankey: {
                    nodeWidth: 5,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        color: 'black',
                        style: {
                            textOutline: 'none',
                            fontSize: '16px',
                        },
                    },
                    label: {
                        style: {
                            fontSize: '16px',
                        },
                    },
                },
            },

			credits: {
				enabled: false
			},

            // xAxis: {
            //     type: 'datetime'
            //   },
            //   yAxis: {
            //     title: {
            //       text: 'Value'
            //     }
            //   },
	
		};
    };

    const handleBackClicked = () => {
        let path = `/universe/summary?universe_id=${universeId}`;
        if (accessToken !== null && accessToken !== '' && accessToken !== undefined) {
            path += `&access_token=${accessToken}`;
        }
        navigate(path, {state: { universeId, accessToken }});
    };

    const handleStartTimeYearChange = (selectedOption) => {
        setStartYear(selectedOption.value);
    };

    const handleEndTimeYearChange = (selectedOption) => {
        setEndYear(selectedOption.value);
    };

    const handleRefreshClicked = () => {
        if (startYear >= endYear) {
            alert('Start year must be less than End year');
            return;
        }

        const applyTimeFilterDiv = document.getElementById('apply-time-filter');
        // update the display of the apply-time-filter div
        applyTimeFilterDiv.style.display = 'block';

        setApplyTimeFilter(true);

        // defer the update of the display of the apply-time-filter div
        setTimeout(() => {
            applyTimeFilterDiv.style.display = 'none';
        }, 2000);
    };

    const handleDownloadReport = async () => {
        console.log('Download report clicked');
        setIsModalVisible(true);
        setProgressMessage('Initializing report generation...');

        // defer 
        setTimeout(() => {
            generatePPT()
            .then(() => {
                setProgressMessage('Report generated successfully!');
                // Close the modal after a short delay or on user acknowledgment
                if (userAccountDataStore.user !== null && userAccountDataStore.user !== undefined) {
                    mixpanelActions.track("Download", { type: 'bizdevReport', user: toJS(userAccountDataStore.user) });
                    amplitudeActions.track("Download", { type: 'bizDevReport', user: toJS(userAccountDataStore.user) });
                }
                setTimeout(() => setIsModalVisible(false), 2000);
            })
            .catch(error => {
                setProgressMessage('Error generating report');
                console.error('Error generating report:', error);
            });
        }, 1000);
    };

 
    async function generatePPT() {
        setIsModalVisible(true);
        setProgressMessage('Initializing report generation...');
    
        let pptx = new pptxgen();
        let today = new Date();
        let monthInWords = today.toLocaleString('default', { month: 'long' });
        let dateLabel = `${monthInWords} ${today.getUTCDate()} ${today.getUTCFullYear()}`;
        pptx = add_initial_slide(pptx, 'Telemetry BizDev Report', `Target: ${targetCompanyName}`, dateLabel);
        console.log('Initial slide created');
    
        const containerIds = ['universe-summary', 'headcount-metrics', 'headcount-hiring-attrition', 'headcount-by-department', 'tenure-distribution', 'talent-flow'];
        const slideTitles = {
            'universe-summary': `Company Collection: Headcount Growth vs Employee Retention`,
            'headcount-metrics': `${targetCompanyName}: Headcount Growth vs Industry Benchmark`,
            'headcount-hiring-attrition': `${targetCompanyName}: Hiring & Attrition vs Industry Benchmark`,
            'headcount-by-department': `${targetCompanyName}: Headcount Distribution by Team`,
            'tenure-distribution': `${targetCompanyName}: Career & Company Tenure`,
            'talent-flow': `${targetCompanyName}: Talent Flow (Last 3 years)`,
        };
    
        // Process each chart sequentially to allow UI updates
        for (let i = 0; i < containerIds.length; i++) {
            const id = containerIds[i];
            setProgressMessage(`Processing chart ${i + 1} of ${containerIds.length}...`);
            await new Promise((resolve, reject) => {
                const chartContainer = document.getElementById(id);
                if (!chartContainer) {
                    console.error(`Chart container not found: ${id}`);
                    reject(`Chart container not found: ${id}`);
                    return;
                }
                    
                html2canvas(chartContainer).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const slideTitle = slideTitles[id];
                    pptx = add_chart_slide(pptx, slideTitle, imgData);
                    resolve();
                });
            });
        }
    
        setProgressMessage('Adding final touches...');
        pptx = add_contacts_slide(pptx);
    
        // Save the PowerPoint
        await pptx.writeFile({ fileName: `Telemetry_BizDev_Report_${targetCompanyName}` });
        console.log('PPTX file written');
        setProgressMessage('Report generated successfully!');
    }
      
    
    return (
        <div>
            {
                dataLoading ? 
                <div style={{ textAlign: "center" }} >
                    <LoadingWithText texts={['Connecting to Telemetry API...', 'Requesting data...', 'Generating charts...', 'Finalzing preview...']} interval={10000} />
                </div>
                :
                <div>
                    {/* <button className="back-button" onClick={handleBackClicked}>Back</button> */}
                    <div style={{textAlign: 'center'}}>
                        <h1>
                            Telemetry BizDev Report Preview: {targetCompanyName}
                        </h1>
                        <h4>
                            Scroll through the preview, make any adjustments as needed and then click Download Report at the bottom to get the full report.
                        </h4>
                    </div>
                    <br/>
                    <br/>
                    <div className='checkbox-group-container'> 
                        <div className='checkbox-container'>
                            <label htmlFor='startTime'>Start Year:</label>
                            &nbsp;&nbsp;
                            <Select
                                name="startYear"
                                options={startYearOptions.map(year => ({value: year, label: year}))}
                                value={{value: startYear, label: startYear}}
                                onChange={handleStartTimeYearChange}
                            />
                        </div>
                        <div className='checkbox-container'>
                            <label htmlFor='endTime'>End Year:</label>
                            &nbsp;&nbsp;
                            <Select
                                name="endYear"
                                options={endYearOptions.map(year => ({value: year, label: year}))}
                                value={{value: endYear, label: endYear}}
                                onChange={handleEndTimeYearChange}
                                
                            />
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button className='button' onClick={handleRefreshClicked}>Apply</button>
                    </div>
                    <div id="apply-time-filter" style={{textAlign: 'center', display: 'none'}}>
                        <LoadingWithText texts={['Applying filter...', 'Finished!']} interval={3000} />
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <p> Select the start and end year and then click Apply to update the charts</p>
                    </div>
                    <div style={{textAlign: 'left', marginLeft: '100px'}}>
                        <h2> Company Collection: Headcount Growth vs Employee Retention
                        </h2>
                    </div>
                    <div className="chart-container" id="universe-summary">
                        <div style={{marginLeft: '100px'}} className='chart-container-left-hc-report' id="universe-chart-container" />
                        <div className='chart-container-right-hc-report' style={{marginRight: '100px'}}>
                            <table id="universe-summary-table" className='company-table' ></table>
                        </div>
                    </div>
                    <hr className="dotted-line" />
                    <div style={{textAlign: 'left', marginLeft: '100px'}}>
                        <h2> {targetCompanyName}: Headcount Growth vs Industry Benchmark
                        </h2>
                    </div>
                    <div id="headcount-metrics" >
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="growth-chart-container" />
                        </div>
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="total-headcount-chart-container" />
                        </div>
                    </div>
                    <hr className="dotted-line" />
                    <div style={{textAlign: 'left', marginLeft: '100px'}}>
                        <h2> {targetCompanyName}: Hiring & Attrition vs Industry Benchmark
                        </h2>
                    </div>
                    <div id="headcount-hiring-attrition" >
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="hiring-attrition-line-chart-container" />
                        </div>
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="hiring-attrition-column-chart-container" />
                        </div>
                    </div>
                    <hr className="dotted-line" />
                    <div style={{textAlign: 'left', marginLeft: '100px'}}>
                        <h2> {targetCompanyName}: Headcount Distribution by Team
                        </h2>
                    </div>
                    <div id = "headcount-by-department">
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="headcount-by-department-chart-container" />
                        </div>
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report'>
                                <table id="headcount-by-department-table" className='company-table'/>
                            </div>
                        </div>
                    </div>
                    <hr className="dotted-line" />
                    <div style={{textAlign: 'left', marginLeft: '100px'}}>
                        <h2> {targetCompanyName}: Career & Company Tenure
                        </h2>
                    </div>
                    <div id="tenure-distribution" >
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="career-tenure-dist-chart-container" />
                        </div>
                        <div className="chart-container">
                            <div style={{marginLeft: '100px', marginRight: '100px'}} className='chart-container-left-hc-report' id="company-tenure-dist-chart-container" />
                        </div>
                    </div>
                    <hr className="dotted-line" />
                    <div style={{textAlign: 'left', marginLeft: '100px'}}>
                        <h2> {targetCompanyName}: Talent Flow (last 3 years)
                        </h2>
                    </div>
                    <div className="chart-container" id="talent-flow">
                        <div style={{marginLeft: '200px', marginRight: '100px'}} className='chart-container-left-talent-bizdev' >
                            <div id="talent-flow-chart-container"></div>
                            <div>
                                <div style={{display: 'flex'}}>

                                    <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                                        <ul style={{ listStyleType: 'none', textAlign: 'left', fontSize: '20px'}}>
                                            <li>Total Inflow: {totalInflow}</li>
                                            <li>Private: {privateInflow}</li>
                                            
                                        </ul>
                                    </div>
                                    <div style={{display: 'flex', flex: '1', alignItems: 'stretch', flexDirection: 'column'}}>
                                        <ul style={{ listStyleType: 'none', textAlign: 'right', marginRight: '200px', fontSize: '20px'}}>
                                            <li>Total Outflow: {totalOutflow}</li>
                                            <li>Pending: {pendingOutflow}</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="dotted-line" />
                    <ProgressModal isVisible={isModalVisible} progressMessage={progressMessage} />
                    <div style={{textAlign: 'center'}}>
                        <button className='button' onClick={handleDownloadReport}> Download Report </button>
                        <p> * For best performance, please use the Google Chrome browser *</p>
                    </div>
                    <br/>
                </div>
            }
        </div>
    );
}

export default BizDevReport;