import React from 'react';
import axios from 'axios';  
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CompanySearchResultsTable } from './company_search_results_table';
import { getApiServrUrl, getNumberOfDaysBetweenDates } from '../../utils/utils';
import { ClipLoader } from 'react-spinners';
import { NavLink } from 'react-router-dom';
import { userAccountDataStore } from '../../stores/user_account_data_store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { apiEventsStore } from '../../stores/api_events_store';
import LoadingWithText from '../common/loading_text';
import { mixpanelActions } from '../../utils/mixpanel_util';
import { amplitudeActions } from '../../utils/amplitude_util';


const CompanySearchWithCreateUniverseComponent = observer(() => {
	const demoText = 'Enter Linkedin URLs as follows:\nhttps://www.linkedin.com/company/apple/\nhttps://www.linkedin.com/company/microsoft/\nhttps://www.linkedin.com/company/google/\n';
			
    const [inputValue, setInputValue] = useState(demoText);
	const [apiData, setApiData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [createUniverseLoading, setCreateUniverseLoading] = useState(false);
	const [universeName, setUniverseName] = useState('');
	const [universeId, setUniverseId] = useState(''); // This is the universe_id of the newly created universe
	const [universeSummaryPath, setUniverseSummaryPath] = useState(''); // This is the path to the Universe Summary page [used in the "View Universe" button

	const [isUnAuthorized, setIsUnAuthorized] = useState(false);  
	const [selectedRows, setSelectedRows] = useState({});
	const [selectAll, setSelectAll] = useState(true);
	const [noResults, setNoResults] = useState([]); // This is used to show the "No Results" message

    const [searchParams] = useSearchParams();
	const navigate = useNavigate();
    const location = useLocation();
	
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isTextContainerVisible, setIsTextContainerVisible] = useState(false);


    const accessToken = searchParams && searchParams.get('access_token') || location && location.state && location.state.accessToken;

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};


	useEffect(() => {
        // check if user data is present or access token is provided
        if (accessToken === null || accessToken === undefined) {
            if (userAccountDataStore.user === null) {
                // accessToken is not used AND user is not logged in, redirect to login page
                navigate('/login');
            }
            else {
                // user is logged in refresh the data
				const refreshUserData = async () => {
					try {
						await userAccountDataStore.refreshUser(userAccountDataStore.user.email);
						if (userAccountDataStore.unAuthorized) {
                            // redirect to login
                            console.log('unAuthorized');
                            navigate('/login');
                        }
					} catch (err) {
						console.log(err);
					}
				};
                refreshUserData();
                if(userAccountDataStore.accessExpiresInDays <= 0) {
                    alert('Access expired. Redirecting to home page...');
                    navigate('/home');
                }
            }
        }
    }, []);
	
	useEffect(() => {
		if (inputValue.trim() === '') {
			// set demo text with urls that are separated by newlines
			setInputValue(demoText);
		}
		// setSelectedData('');
	}, []);
	
	const handleInputFocus = () => {
		if (inputValue === demoText) {
			setInputValue('');
		}
	};

	const handleInputBlur = () => {
		if (inputValue.trim() === '') {
			setInputValue(demoText);
		}
	};

	const handleSearchClick = async () => {
		if (inputValue.trim() === '' || inputValue.trim() === demoText) {
			return;
		}

		setIsLoading(true);
		setApiData([]);
		setSelectedRows({});
		setNoResults([]);
		setUniverseName('');

		let apiUrl = getApiServrUrl();
        apiUrl += process.env.REACT_APP_API_SEARCH_ENDPOINT;
       
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
			decompress: true,
			// withCredentials: true,
        };
		if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }
		
		// trim inputValue to get the shorthand_names
		let linkedin_urls = [];
		if (inputValue.includes('\n')) {
			// split the inputValue by newline
			linkedin_urls = inputValue.trim().split('\n');
		}
		else {
			// inputValue is a single url
			linkedin_urls.push(inputValue);
		}
		// remove any spaces from the linkedin_urls
		linkedin_urls = linkedin_urls.map(url => url.trim());
		// remove any `\n` chars from the linkedin_urls
		linkedin_urls = linkedin_urls.map(url => url.replace(/\n/g, ''));
		// remove empty strings from the linkedin_urls
		linkedin_urls = linkedin_urls.filter(url => url !== '');

		let shorthand_names = [];
		for (let i = 0; i < linkedin_urls.length; i++) {
			let url = linkedin_urls[i];
            // check if last character is a slash
            if (url[url.length - 1] === '/') {
                url = url.slice(0, -1);
            }

			// remove the protocol
			url = url.replace(/(^\w+:|^)\/\//, '');
			let parts = url.split('/');

			// the third part is the shorthand_name
			let shorthand_name = parts[2];
			shorthand_names.push(escape(shorthand_name));
		}

		let data = {
			'shorthand_names': shorthand_names
		}

        try {
            const response = await axios.post(apiUrl, data, config)
            if (response.status === 200 & response.data.data.results.length !== 0) {
                const responseData = JSON.parse(response.data.data.results);
                
                setApiData(responseData);
				// select all the rows by default
				const newSelectedRows = {};
				responseData.forEach((item, index) => {
					newSelectedRows[index] = item;
				});
				setSelectedRows(newSelectedRows);
                
				if (responseData.length !== linkedin_urls.length) {
					// search didnt return results for all the companies
					let noResults = [];
					let responseDataLinkedinUrls = [];
					for (let i = 0; i < responseData.length; i++) {
						responseDataLinkedinUrls.push(responseData[i].linkedin_url);
					}

					for (let i = 0; i < linkedin_urls.length; i++) {
						if (!responseDataLinkedinUrls.includes(linkedin_urls[i])) {
							noResults.push(linkedin_urls[i]);
						}
					}

					setNoResults(noResults);
				}
            }
			
			if (userAccountDataStore.user !== null) {

				mixpanelActions.track("Company Search", {
					user: toJS(userAccountDataStore.user),
					linkedinUrls: linkedin_urls,
				});
				amplitudeActions.track("Company Search", {
					user: toJS(userAccountDataStore.user),
					linkedinUrls: linkedin_urls,
				});

				// track search event
				apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
					companyId: null,
					eventType: 'search',
					eventData: {
						"url": apiUrl,
						"path": process.env.REACT_APP_API_SEARCH_ENDPOINT,
						"dataset": null,
						"user_agent": navigator.userAgent,
					},
				});
			}
        }
        catch (error) {
            console.log(error);
			if (error.response && error.response.status === 401 || error.response.status === 403) {
				setIsUnAuthorized(true);
			}
            // setIsLoading(false);
        }
		finally {
			setIsLoading(false);
		}

	};

	const handleClear = () => {
		setInputValue(demoText); // Clear the input field
		setApiData([]);        // Clear the data
		setSelectedRows({}); // Clear the selected rows
		setNoResults([]); // Clear the noResults array
		setUniverseId(''); // Clear the universeId
		setUniverseSummaryPath(''); // Clear the universeSummaryPath
		setIsLoading(false); // Clear the loading flag
	};

	const handleCheckboxChange = (index, item) => {
		setSelectedRows(prevState => {
		  const newState = { ...prevState };
	  
		  if (prevState[index]) {
			delete newState[index];
		  } else {
			newState[index] = item;
		  }
	  
		  // Check if all rows are selected after this change
		  if (Object.keys(newState).length === apiData.length) {
			setSelectAll(true);
		  } else {
			setSelectAll(false);
		  }
	  
		  return newState;
		});
	  };

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedRows({});
		} 
		else {
			const newSelectedRows = {};
			apiData.forEach((item, index) => {
				newSelectedRows[index] = item;
			});
			setSelectedRows(newSelectedRows);
		}
		
		setSelectAll(!selectAll);
	};
	  

	const handleCreateUniverse = async () => {
		if (!universeName) {
		  alert('Please provide a Collection Name.');
		  return;
		}
		
		if (Object.keys(selectedRows).length === 0) {
			alert('Please select at least one company to save to a Collection.');
			return;
		}
	  
		
		setIsModalVisible(true);
		
		setCreateUniverseLoading(true);
		let data = {
			'companies': [],
			'universe_name': universeName,
			'client_email': toJS(userAccountDataStore.user).email,
		};

		// Add the selected companies to the data object
		for (let key in selectedRows) {
			// check if key is true
			if (selectedRows[key]) {
				data.companies.push({
					'company_id': selectedRows[key].company_id,
					'linkedin_url': selectedRows[key].linkedin_url,
				});
			}
		};

		let config = { 
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + accessToken,
			},
			decompress: true,
			// withCredentials: true,
		};
		if (accessToken === null || accessToken === undefined || accessToken === '') {
            config['withCredentials'] = true;
        }
		
		try {
			let apiUrl = getApiServrUrl();
			apiUrl += process.env.REACT_APP_API_UNIVERSE_CREATE_ENDPOINT;
			console.log(`CompanySearch: Creating universe at ${apiUrl}...`);
			const response = await axios.post(apiUrl, data, config)
			if (response.status === 200 && response.data.data.universe_id !== '') {
				const universeId = response.data.data.universe_id;
				setUniverseId(universeId);
				if(userAccountDataStore.user !== null) {
					setUniverseSummaryPath(`/universe/summary?universe_id=${universeId}`);
				}
				else {	
					setUniverseSummaryPath(`/universe/summary?universe_id=${universeId}&access_token=${accessToken}`);
				}
			}

			if (userAccountDataStore.user !== null) {
				mixpanelActions.track("Save Collection", {
					user: toJS(userAccountDataStore.user),
					universeName: universeName,
					companies: data.companies,
				});
				amplitudeActions.track("Save Collection", {
					user: toJS(userAccountDataStore.user),
					universeName: universeName,
					companies: data.companies,
				});

				// track create universe event
				apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
					companyId: null,
					eventType: 'universe_create',
					eventData: {
						"url": apiUrl,
						"path": process.env.REACT_APP_API_UNIVERSE_CREATE_ENDPOINT,
						"dataset": null,
						"user_agent": navigator.userAgent,
						"company_ids": data.companies.map(company => company.company_id),
					},
				});
			}
		}
		catch (error) {
			console.log(error);
			if (error.response && error.response.status === 401 || error.response.status === 403) {
				setIsUnAuthorized(true);
			}
		}
		finally {
			setCreateUniverseLoading(false);
			setIsModalVisible(false);
  			setIsTextContainerVisible(true);
			setUniverseName('');
		}
	};
	
	const handleOkClick = () => {
		setIsTextContainerVisible(false);
	};


	if (isUnAuthorized) {
        return (
            <div style={{ textAlign: "center" }} >
                <p>Oops, something went wrong. Please contact your admin for more details.</p>
            </div>
        );
    }
	else {
		return (
			<div style={{textAlign: 'center'}}>
				{isModalVisible && <div className="modalOverlay"></div>}
				<h1 id="page-title">
					Company Search
				</h1>
				<div>
					<p>
						<span>
							Please enter a list LinkedIn URLs to search for companies.
						</span>
					</p>
				</div>
				<div style={{alignItems: 'center'}}>
					<textarea
						id='linkedin-urls'
						value={inputValue} 
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						style={{ width: '75%', height: '100px', fontSize: '16px', padding: '10px', borderRadius: '20px' }} 
					/>
					<br/>
					<button 
						className='button'
						onClick={handleSearchClick}
					>
						Search
					</button>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<button 
						className='button'
						onClick={handleClear}
					>
						Clear
					</button>
				</div>
				<br/>
				{/* Conditionally display spinner based on createUniverseLoading */}
				{ createUniverseLoading && (<div style={{textAlign: 'center'}}> <LoadingWithText texts={["Creating collection...", "Saving to the DB...", "Setting up the collection page...", "Finished!"]} interval={8000}/></div>) }
				{ apiData.length === 0
					// check if isLoading is true
					? (isLoading
						// if isLoading is true, show Loading...
						? (<div style={{textAlign: 'center'}}> <LoadingWithText texts={["Searching the DB...", "Setting up the results table...", "Adding final touches...", "Finished!"]} interval={8000}/> </div>)
						// if isLoading is false, show nothing
						: (<div></div>)
					)
					// if apiData is not empty, show the table
					: (<div style={{alignItems: 'center', margin: '20px', textAlign: 'center'}}>
						<hr className='dotted-line'/>
						{/* <p><b>Search query URLs: {inputValue.split(',').length}</b> &nbsp; <b>Results: {apiData.length}</b> &nbsp; <b>No results: {noResults.length}</b></p>
						{ noResults.length > 0 && (
							<p><b>Not Found: </b>{noResults.join(', ')}</p>
						)} */}
						
						<p>Scroll through the results below to select one or more companies you wish to add to your Collections and then click on the `Save Collection` button below.</p>
						<p>You can also view company insights by clicking on any of the Insights buttons.</p>
						<CompanySearchResultsTable 
							searchDataItems={apiData} 
							selectedRows={selectedRows} 
							handleSelect={handleCheckboxChange} 
							handleSelectAll={handleSelectAllChange} 
							selectAll={selectAll} 
							accessToken={accessToken}
							onlySearch={false}
						/>
						
						<hr className='dotted-line'/>
						</div>
					)
				}
				<br />
				{/* Conditionally display the "Create Universe" button based on the selected rows */}
				{ apiData.length > 0 && (
					<div style={{textAlign: 'center'}}>
						<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<label>
								Collection Name: &nbsp;
								<input 
									type="text" 
									value={universeName}
									style={{ fontSize: '16px', padding: '10px', width: '200px', borderRadius: '10px', marginTop: '10px' }} 
									onChange={(e) => setUniverseName(e.target.value)} 
									
								/>
							</label>
							{/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<label>
								Registered email: &nbsp;
								<input 
									type="email" 
									value={clientEmail}
									style={{ fontSize: '16px', padding: '10px', width: '200px', borderRadius: '10px', marginTop: '10px' }} 
									onChange={(e) => setClientEmail(e.target.value)} 
									
								/>
							</label> */}
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<button 
								className='button'
								onClick={handleCreateUniverse}
							>
								Save Collection
							</button>
							<div style={{ display: 'flex', justifyContent: 'center', marginLeft: '75px' }}>
							{ universeId !== '' && (
								<NavLink 
									style={{border: 'black 2px solid'}}
									target="_blank" rel="noopener noreferrer"
									className='button'
									to={universeSummaryPath}
									state={{universeId: universeId, accessToken: accessToken}}
								>
									View Collection
								</NavLink>
							)}
						</div>
						</div>
					</div>
				)}
				<br/>
				{universeId !== '' && isTextContainerVisible && (
				<div className="textContainer">
					<div className='textHeader'>Success</div>
					<div className='textContent'>
						Universe: {universeName} created successfully!
						<br/>
						<br/>
						Click <a href={universeSummaryPath} target="_blank" rel="noopener noreferrer">here</a> to view the Universe.
					</div>
					<button onClick={handleOkClick}>OK</button>
				</div>
				)}
			</div>
		);
	}
});

export default CompanySearchWithCreateUniverseComponent;
