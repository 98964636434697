import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { userAccountDataStore } from "./user_account_data_store";
import { apiEventsStore } from "./api_events_store";


class GrowthDataStore {
    loading = false;
    data = {};
    universeData = {};
    error = null;
    unAuthorized = false;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchGrowthData(companyId, universeId, accessToken, dataset) {

        // check if data already exists
        // and company id is present in data
        if (this.data[companyId] && this.data[companyId].length > 0) {
            console.log(`GrowthDataStore: Data already exists for company ${companyId}`);
            return;
        }

        this.loading = true;
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_GROWTH_ENDPOINT;
            
            let data = {
                "company_ids": [parseInt(companyId)],
                "dataset": dataset
            }
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                //withCredentials: true,
                decompress: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200 && response.data.data.headcount.length !== 0) {
                const jsonData = JSON.parse(response.data.data.headcount);
                
                console.log(`GrowthDataStore: Fetched growth data from ${apiUrl}, ${jsonData.length} records`);
                // set data in store
                this.data[companyId] = jsonData;
                this.loading = false;
            }

        
            if (universeId !== null && universeId !== undefined) {
                // get universe growth avg data
                let apiUrl = getApiServrUrl();
                apiUrl += process.env.REACT_APP_API_UNIVERSE_GROWTH_AVG_ENDPOINT;
                
                console.log(`GrowthDataStore: Fetching universe growth avg data from ${apiUrl}...`);
                let data = {
                    "universe_id": `${universeId}`,
                    "dataset": dataset,
                }
                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    },
                    //withCredentials: true,
                    decompress: true,
                };
                if (accessToken === null || accessToken === undefined || accessToken === '') {
                    config['withCredentials'] = true;
                }

                try {
                    const response = await axios.post(apiUrl, data, config);
                    if (response.status === 200 && response.data.data.universe_headcount_company_avg.length !== 0) {
                        const jsonData = JSON.parse(response.data.data.universe_headcount_company_avg);
                        this.universeData[universeId] = jsonData;
                        console.log(`GrowthDataStore: Fetched universe growth avg data from ${apiUrl}, ${jsonData.length} records`);
                    }
                } catch (err) {
                    console.log(err);
                    if (err.response !== undefined && (err.response.status === 401 || err.response.status === 403)) {
                        this.unAuthorized = true;
                    }
                    
                }
            }

            if (userAccountDataStore.user !== null) {
                // track growth event
                apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                    companyId,
                    eventType: 'growth',
                    eventData: {
                        "url": apiUrl,
                        "path": process.env.REACT_APP_API_GROWTH_ENDPOINT,
                        "dataset": dataset,
                        "user_agent": navigator.userAgent,
                    },
                });
            }
        }
        catch(err) {
            console.log(err);
            this.error = err;
            this.loading = false;
        }
    }
}

export const growthDataStore = new GrowthDataStore();
