import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";
import { apiEventsStore } from "./api_events_store";
import { userAccountDataStore } from "./user_account_data_store";

class TalentInflowAndOutflowDataStore {
    loading = false;
    data = null;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchTalentFlowData(companyId, accessToken, startDate, endDate, geo1, dept, seniority, dataset) {
 
        try {
                let apiUrl = getApiServrUrl();
                apiUrl += process.env.REACT_APP_API_TALENT_FLOW_DATA_ENDPOINT;

                let data = {
                    "company_id": parseInt(companyId),
                    "start_date": startDate,
                    "end_date": endDate,
                    "geo1": geo1,
                    "dept": dept,
                    "seniority": seniority,
                    "dataset": dataset
                };

                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    },
                    //withCredentials: true,
                    decompress: true,
                };
                if (accessToken === null || accessToken === undefined || accessToken === '') {
                    config['withCredentials'] = true;
                }

                console.log(`TalentInflowAndOutflowDataStore: Fetching talent flow data from ${apiUrl}...`);

                let response = await axios.post(apiUrl, data, config);
                if (response.status === 200) {
                    let talentFlowData = JSON.parse(response.data.data.talent_flow);
                    console.log(`TalentInflowAndOutflowDataStore: Fetched talent flow data from ${apiUrl}, ${talentFlowData.length} records`);
                    if (this.data == null) {
                        this.data = {};
                    }

                    this.data[companyId] = talentFlowData;
                }

                if (userAccountDataStore.user !== null) {
                    // track team growth event
                    apiEventsStore.trackApiEvents(userAccountDataStore.user.uuid, {
                        companyId,
                        eventType: 'talent',
                        eventData: {
                            "url": apiUrl,
                            "path": process.env.REACT_APP_API_TALENT_FLOW_DATA_ENDPOINT,
                            "dataset": dataset,
                            "user_agent": navigator.userAgent,
                        },
                    });
                }

        } catch (error) {
            console.log(error);
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                this.error = error;
            }
        }
    }

}

export const talentInflowAndOutflowDataStore = new TalentInflowAndOutflowDataStore();
