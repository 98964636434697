import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl, getNumberOfDaysBetweenDates } from "../utils/utils";

const noByPassTelemetryEmails = ['lalit@telemetryllc.com', 'jonathan@telemetryllc.com', 'lalit@telemetry-llc.com', 'jonathan@telemetry-llc.com'];

class UserAccountDataStore {
    errorMsg = '';
    user = null;
    loginSuccess = false;
    accessExpiresInDays = null;
    unAuthorized = false;

    constructor() {
        makeAutoObservable(this);
        this.rehydrate();
    }

    rehydrate() {
        let user = localStorage.getItem('user');
        let accessExpiresInDays = localStorage.getItem('accessExpiresInDays');
        if (user) {
            this.user = JSON.parse(user);
        }
        if (accessExpiresInDays) {
            this.accessExpiresInDays = accessExpiresInDays;
        }
    }

    setUserData(user) {
        this.user = user;
        // check email domain
        let emailDomain = this.user.email.split('@')[1];
        if (emailDomain === 'telemetryllc.com' || emailDomain === 'telemetry-llc.com') {
            // only bypass for certain emails
            if (!noByPassTelemetryEmails.includes(this.user.email)) {
                // bypass trial period
                this.accessExpiresInDays = 10000;
            }
            else {
                this.accessExpiresInDays = getNumberOfDaysBetweenDates(new Date(), this.user.trial_end_date);
            }
        }
        else {
            this.accessExpiresInDays = getNumberOfDaysBetweenDates(new Date(), this.user.trial_end_date);
        }
        
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('accessExpiresInDays', this.accessExpiresInDays);
    }

    clearUserData() {
        this.user = null;
    }

    async loginUser(username, password) {
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_LOGIN_USER_ENDPOINT;
            console.log(`UserAccountDataStore: Fetching login data from ${apiUrl}...`);

            let data = {
                "email": username,
                "password": password,
            };
            
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                // withCredentials: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                // set user data in the store
                this.setUserData(response.data.data.user);
                this.loginSuccess = true;
                this.unAuthorized = false;
            }

        }
        catch (error) {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                console.log(error.response);
                this.errorMsg = error.response.data.error;
                this.unAuthorized = true;
            }
            else if (error.response.status === 500) {
                console.log(error.response);
                this.errorMsg = error.response.data.error;
            }
            else {
                console.log(error);
                this.errorMsg = "Oops, something went wrong. Please try again in a few mins.";
            }
        }
    };

    async refreshUser(username) {
        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_GET_USER_ENDPOINT;
            console.log(`UserAccountDataStore: Fetching user data from ${apiUrl}...`);

            let data = {
                "email": username,
            };
            
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                decompress: true,
                withCredentials: true,
            };

            const response = await axios.post(apiUrl, data, config);
            if (response.status === 200) {
                // set user data in the store
                this.setUserData(response.data.data.user);
                this.unAuthorized = false;
            }

        }
        catch (error) {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403) {
                console.log(error.response);
                this.errorMsg = error.response.data.error;
                this.unAuthorized = true;
            }
            else if (error.response.status === 500) {
                console.log(error.response);
                this.errorMsg = error.response.data.error;
            }
            else {
                console.log(error);
                this.errorMsg = "Oops, something went wrong. Please try again in a few mins.";
            }
        }
    };

}

export const userAccountDataStore = new UserAccountDataStore();
