import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getApiServrUrl } from "../utils/utils";


class MetadataStore {
    loading = false;
    data = null;
    isUnAuthorized = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchMetadata(companyId, accessToken, dataset) {
        // check if data already exists
        // and company id is present in data
        if (this.data && this.data[companyId]) {
            console.log(`MetaDataStore: Data already exists for company ${companyId}`);
            return;
        }
        
        this.loading = true;

        try {
            let apiUrl = getApiServrUrl();
            apiUrl += process.env.REACT_APP_API_METADATA_ENDPOINT;
            console.log('MetaDataStore: Fetching company metadata from ' + apiUrl + '...');
            let data = {
                "company_ids": [parseInt(companyId)],
                "dataset": dataset,
            };
    
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                decompress: true,
                // withCredentials: true,
            };
            if (accessToken === null || accessToken === undefined || accessToken === '') {
                config['withCredentials'] = true;
            }

            const response = await axios.post(apiUrl, data, config);

            if (response.status === 401 || response.status === 403) {
                this.isUnAuthorized = true;
                return;
            }
            
            if(response.status === 200 && response.data.data.metadata.length !== 0) {
                const metaData = JSON.parse(response.data.data.metadata);
                console.log(`MetaDataStore: Fetched company metadata from ${apiUrl}, ${metaData.length} records`);
                // set data in store
                this.data = {};
                this.data[companyId] = {
                    'companyName': metaData[0].name,
                    'publicProfileCount': metaData[0].num_valid_public_profiles,
                };
                this.loading = false;
            }

        }
        catch(err) {
            console.log(err);
            if (err.response !== undefined && (err.response.status == 401 || err.response.status === 403)) {
                this.isUnAuthorized = true;
            }
            this.error = err;
            this.loading = false;
        }
    
    }
}

export const metadataStore = new MetadataStore();
