export function getApiServrUrl() {
    return process.env.REACT_APP_API_SERVER_URL;
};

export function getGoogleAnalyticsId() {
    return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
};

export function toTitle(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export function numberToCurrencyUSDFormatter(number) {
    let formatter = Intl.NumberFormat('en-US', {'notation': 'compact', 'compactDisplay': 'short', 'style': 'currency', 'currency': 'USD'});
    return formatter.format(number);
}

export function numberFormatter(number) {
    let formatter = Intl.NumberFormat('en-US', {'notation': 'compact', 'compactDisplay': 'short'});
    return formatter.format(number);
}

export function putCommasInNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function customSort(arr, customSortOrder) {
    // Separate arr into those defined in the customSortOrder and those not
    const inOrder = [];
    const notInOrder = [];
  
    for (let i = 0; i < arr.length; i++) {
      if (customSortOrder.includes(arr[i])) {
        inOrder.push(arr[i]);
      } else {
        notInOrder.push(arr[i]);
      }
    }
  
    // Sort arr defined in customSortOrder
    inOrder.sort((a, b) => {
      return customSortOrder.indexOf(a) - customSortOrder.indexOf(b);
    });
  
    // Sort arr not defined in customSortOrder alphabetically
    notInOrder.sort();
  
    // Combine both arrays
    return [...inOrder, ...notInOrder];
  }

// function to get the last date of the month given string date
export function getLastDateOfMonth(dateString) {
    let date = new Date(dateString);
    let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDate;
}

export function getFriendlyMetricName(metric) {
    switch (metric) {
        case 'ltm_net_hc_growth':
            return 'Net Headcount Growth (Last 12 Months)';
        case 'ltm_attrition_rate':
            return 'Attrition rate (Last 12 Months)';
        case 'ltm_addition_rate':
            return 'Hiring rate (Last 12 Months)';
        case 'ltm_growth_productivity':
            return 'Growth Efficiency (Last 12 Months)';
        case 'ltm_avg_net_hc_growth_benchmark':
            return 'Benchmark Avg Net Growth (Last 12 Months)';
        case 'ltm_avg_growth_productivity_benchmark':
            return 'Benchmark Avg Growth Efficiency (Last 12 Months)';
        case 'ltm_avg_addition_rate_benchmark':
            return 'Benchmark Avg Hiring (Last 12 Months)';
        case 'ltm_avg_attrition_rate_benchmark':
            return 'Benchmark Avg Attrition (Last 12 Months)';
        case 'ltm_company_pct_female':
            return 'Pct Female, Company Avg';
        case 'ltm_attrition_rate_female':
            return 'Pct Female, Attrits (Last 12 months)';
        case 'ltm_addition_rate_female':
            return 'Pct Female, New Hires (Last 12 months)';
        default:
            return metric; // return the metric as is if no friendly name is defined
    }
}

export function getPastDate(duration, dataVersionDateStr) {
    //const dataVersionDate = new Date(process.env.REACT_APP_DATA_VERSION_DATE);
    let dataVersionDate = new Date(dataVersionDateStr);
    switch (duration) {
        case '3 months':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 3);

        case '6 months':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 6);
        case '1 year':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 12);
        case '2 years':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 24);
        case '3 years':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 36);
        case '5 years':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 60);
        case '7 years':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 84);
        case '10 years':
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 120);
        default:
            return dataVersionDate.setMonth(dataVersionDate.getUTCMonth() - 3);
    };
};

export function getNumberOfDaysBetweenDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = end - start;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
}
