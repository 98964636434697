export const ProgressModal = ({ isVisible, progressMessage }) => {
    if (!isVisible) return null;

    return (
        <div className="modal">
        <div className="modal-content">
            <h2>Download BizDev Report</h2>
            <h3> Please stay on the current tab until the download completes. Thank you!</h3>
            <p>{progressMessage}</p>
            {/* You can also add a progress bar here */}
        </div>
        </div>
    );
}
  